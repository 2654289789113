.file-upload {
  cursor: pointer;
}

.embeded-img {
  width: 64px;
  height: 64px;
}

.preview-img {
  width: fit-content;
  height: 64px;
  width: 64px;
}

.preview-img img {
  height: 64px;
  width: 64px;
}

.close-icon {
  padding: 0 6px;
  right: 0;
  top: 0;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}

.iframe-close {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}

.add-article-model .modal-dialog {
  max-width: 1000px;
}

.add-tag-btn {
  width: 36px;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  margin-left: 20px;
  background-color: blue;
  border-radius: 50%;
  color: white;
  cursor: pointer;
}

.tags-box {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin-top: 16px;
}

.tags-box p {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 4px 10px;
}

.tags-box span::after {
  content: "x";
  margin-left: 10px;
  cursor: pointer;
}

.cke_path{
  display: none;
}