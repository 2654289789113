hr {
  color: black;
  opacity: 1;
}

.dropdown-item:hover {
  color: black !important;
}

ol.breadcrumb {
  margin-bottom: 0;
}

.support-main{
  padding: 0px 63px 86px 57px;
  background-color: white;
}


.caseinfo-main {
  /* margin-top: 80px; */
  padding: 0px 63px 86px 57px;
  background-color: white;
}

.addcase-main {
  margin-top: 80px;
  padding: 30px 40px 60px;
  background-color: white;
}

.support-main input:focus {
  border-color: #e1e4e6;
}

.support-search > span {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

/* .support-search > input {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
} */

.support-dropdown > button {

  width: 100%;
  text-align: start;
  border-color: #e1e4e6;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.support-dropdown > button:hover {
  background-color: transparent;
  color: black;
  border-color: #e1e4e6;
}

.btn-outline-secondary.dropdown-toggle.show {
  background-color: transparent;
  color: black;
  border-color: #e1e4e6;
}

.support-dropdown > button:focus {
  background-color: transparent;
}

.support-dropdown > button:after {
  margin: 0;
  position: absolute;
  top: calc(50% - 6px);
  right: 5%;
}

/* TABLE */

/* .support-table table thead tr {
  background-color: #c4c3c3;
} */



.support-table table th,
.support-table table td {
  white-space: nowrap;
}

.support-table table thead th:nth-child(1),
.support-table table tbody td:nth-child(1) {
  width: 12%;
}

.support-table table thead th:nth-child(2),
.support-table table tbody td:nth-child(2) {
  width: 42%;
}

.support-table table thead th:nth-child(3),
.support-table table tbody td:nth-child(3) {
  width: 15%;
}

.support-table table thead th:nth-child(4),
.support-table table tbody td:nth-child(4) {
  width: 10%;
}

.support-table table thead th:nth-child(5),
.support-table table tbody td:nth-child(5) {
  width: 11%;
}

.support-table table tbody td span {
  /* color: #bdbdbd; */
  border-bottom: 1px solid #bdbdbd;
  cursor: pointer;
}

/* pagination */

.pagination .page-item {
  margin-bottom: 0;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  white-space: nowrap;
}

/* .page-item:first-child .page-link:after {
  content: "Previous";
  margin-left: 8px;
}

.page-item:last-child .page-link::before {
  content: "Next";
  margin-right: 8px;
} */

.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.pagination .active {
  background-color: #92278f !important;
}

.pagination .active .page-link {
  z-index: 3;
  border-color: inherit;
}

/* CASEINFO */

.caseinfo-main .caseinfo-table {
  border: 1px solid #e1e4e6;
}

.caseinfo-main .caseinfo-table tr th,
.caseinfo-main .caseinfo-table tr td {
  white-space: nowrap;
}

.caseinfo-main .caseinfo-table tbody tr:nth-child(even) td:first-child {
  color: #bdbdbd;
}

.caseinfo-main .caseinfo-table tbody tr:nth-child(odd) td:last-child {
  color: #bdbdbd;
}

/* .caseinfo-main .caseinfo-table tbody tr td:first-child {
  border-right: 1px solid black;
} */

.correspondence-message {
  padding: 18px 40px 14px 18px;
}

.case-inf0-status {
  display: flex;
  align-items: center;
}

.correspondence-message > div:first-child {
  height: 115px;
  overflow: hidden;
}

.caseinfo-dropdown .dropdown-menu {
  padding: 10px 0;
  min-width: 40%;
}

@media (max-width: 575px) {
  .correspondence-message {
    padding: 8px 20px 14px 20px;
  }
}

/* ADDCASE */

.text-muted {
  color: #9b999f !important;
}

.addcase-dropdown > button {
  position: relative;
  width: 50%;
  text-align: start;
  border-color: #e1e4e6;
  min-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.addcase-dropdown > button:hover {
  background-color: transparent;
  color: black;
  border-color: #e1e4e6;
}

.addcase-dropdown .btn-outline-secondary.dropdown-toggle.show {
  background-color: transparent;
  color: black;
  border-color: #e1e4e6;
}

span.pe-5 {
 padding-left: 1rem;
font-weight: 700;
}

.addcase-dropdown > button:after {
  position: absolute;
  margin: 0;
  right: 5%;
  top: calc(50% - 6px);
}

.addcase-dropdowns {
  height: 150px;
}

/* .options {
  min-height: 60px;
} */

@media (max-width: 767px) {
  .addcase-main {
    margin-top: 80px;
    padding: 30px 20px 60px;
  }
}

.creat-case-btn {
  display: flex;
  justify-content: flex-end;
}
td.table-flex-instatus {
  color: black !important;
}

td.table-flex-instatus span{
padding-left:1rem;
}
span.user-co{
  padding-left: 1rem;
}

.text-end.addcase-two{
  display: flex;
  align-items: flex-start;
  gap: 27px;
  justify-content: flex-end;
}

.close-typer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.table-flex-instatus span{
  padding-left: 1rem;
}

.caseinfo-table-img.d-flex.justify-content-between.overflow-auto.gap-4.pe-3 a {
  padding-left: 0.5rem;
}

